<template>
  <v-alert v-model="banner.open" :color="banner.color" :dismissible="banner.dismissable">
    <div :class="styleClass">
      {{ banner.text }}
    </div>
  </v-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  name: "Banner",
  computed: {
    ...mapState({
      banner: (state) => state.banner.banner,
    }),
    styleClass() {
      return this.banner.textColor + " text-center font-weight-black";
    },
  },
  watch: {
    banner() {
      if (this.banner.open && this.banner.timeout > 0) {

        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.$store.dispatch("banner/close");
        }, this.banner.timeout);
      }
    },
  },
};
</script>

<style scoped>
</style>
